import datasets from './datasets.json';
import data_centers from './data_centers.json';
import enrichment_rules from './enrichment_rules.json';
import executive_summary from './executive_summary.json';
import translation from './translation.json';
import budget_receivers from './budget_receivers.json';
import advanced_chargeback from './advanced_chargeback.json';
import checking_account from './checking_account.json';

const translations = {
  ...datasets,
  ...data_centers,
  ...enrichment_rules,
  ...executive_summary,
  ...advanced_chargeback,
  ...budget_receivers,
  ...checking_account,
  ...translation,
};

export default translations;
