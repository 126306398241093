import AnomaliesRoutes from './anomalies/Routes';
import BudgetRoutes from './budget/Routes';
import ForecastRoutes from './forecast/Routes';
import TagDictionaryRoutes from './tagDictionary/Routes';
import DataCentersRoutes from './dataCenters/Routes';
import DatasetsRoutes from './datasets/Routes';
import AlarmsRoutes from './alarms/Routes';
import WorkspacesRoutes from './workspaces/Routes';
import TimelineRoutes from './timeline/Routes';
import DraftsRoutes from './drafts/Routes';
import CalculatedFields from './calculatedFields/Routes';
import GlobalFields from './globalFields/Routes';
import Reports from './reports/Routes';
import ExecutiveSummary from './executiveSummary/Routes';
import AdvancedChargeback from './advancedChargeback/Routes';
import { Switch, Route, Redirect } from 'react-router-dom';

function Routes() {
  return (
    <Switch>
      <Route path="/lighthouse/drafts*">
        <DraftsRoutes />
      </Route>

      <Route path="/lighthouse/anomalies*">
        <AnomaliesRoutes />
      </Route>

      <Route path="/lighthouse/budget/advanced-chargeback*">
        <AdvancedChargeback />
      </Route>

      <Route path="/lighthouse/budget*">
        <BudgetRoutes />
      </Route>

      <Route path="/lighthouse/forecast*">
        <ForecastRoutes />
      </Route>

      <Route path="/lighthouse/tag-dictionary*">
        <TagDictionaryRoutes />
      </Route>

      <Route path="/lighthouse/data-centers*">
        <DataCentersRoutes />
      </Route>

      <Route path="/lighthouse/datasets*">
        <DatasetsRoutes />
      </Route>

      <Route path="/lighthouse/alarms*">
        <AlarmsRoutes />
      </Route>

      <Route path="/lighthouse/workspaces*">
        <WorkspacesRoutes />
      </Route>

      <Route path="/lighthouse/timeline*">
        <TimelineRoutes />
      </Route>

      <Route path="/lighthouse/calculated-fields*">
        <CalculatedFields />
      </Route>

      <Route path="/lighthouse/global-fields*">
        <GlobalFields />
      </Route>

      <Route path="/lighthouse/reports*">
        <Reports />
      </Route>

      <Route path="/lighthouse/executive-summary*">
        <ExecutiveSummary />
      </Route>

      <Redirect from="/lighthouse" to="/lighthouse/workspaces" />
      <Redirect from="/workspaces" to="/lighthouse/workspaces" />
    </Switch>
  );
}

export default Routes;
