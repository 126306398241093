import {
  AlertOutlined,
  AppstoreOutlined,
  BookOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  LineChartOutlined,
  FileOutlined,
  CalculatorOutlined,
  GlobalOutlined,
  ExceptionOutlined,
  RiseOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import getOnlyVisibleMenuItems from '@/common/onlyVisibleMenuItems';
import { pierRoles } from '@/context/AuthContext';
import { getAllProvidersKeys } from '@/common/cloudProviders';

const items = ({ t, adminRole, provider, featureFlags }) => {
  const allProvidersExceptFocus = getAllProvidersKeys({ except: ['focus'] });
  const exceptFocusAndOnPrem = getAllProvidersKeys({
    except: ['focus', 'onpremise'],
  });

  const allItems = [
    {
      key: 'lighthouse/workspaces',
      label: t('menu.sidebar.workspaces'),
      icon: <AppstoreOutlined />,
    },
    {
      key: 'lighthouse/executive-summary',
      label: t('lighthouse:executive.summary.title'),
      icon: <LineChartOutlined />,
      hide: featureFlags.has('executive-summary') ? true : undefined,
      providers: exceptFocusAndOnPrem,
      children: [
        {
          key: 'lighthouse/executive-summary/reports',
          label: t('common.report'),
        },
        {
          key: 'lighthouse/executive-summary/personas',
          label: 'Personas',
          permissions: pierRoles,
        },
        {
          key: 'lighthouse/executive-summary/templates',
          label: 'Templates',
          permissions: pierRoles,
        },
      ],
    },
    {
      key: 'lighthouse/reports',
      label: t('common.reports'),
      icon: <ContainerOutlined />,
    },
    {
      key: 'lighthouse/calculated-fields',
      label: t('menu.sidebar.calculated.fields.custom'),
      icon: <CalculatorOutlined />,
    },
    {
      key: 'lighthouse/global-fields',
      label: t('menu.sidebar.calculated.fields.pier'),
      icon: <GlobalOutlined />,
      permissions: pierRoles,
    },
    {
      key: 'lighthouse/data-centers',
      label: t('menu.sidebar.on.premise.costs'),
      icon: <ClusterOutlined />,
      providers: ['onpremise'],
    },
    {
      key: 'lighthouse/drafts',
      label: t('menu.sidebar.drafts'),
      icon: <FileOutlined />,
    },
    {
      key: 'lighthouse/timeline',
      label: t('menu.sidebar.timelines'),
      icon: <LineChartOutlined />,
      providers: allProvidersExceptFocus,
    },
    {
      key: 'lighthouse/budget',
      label: t('menu.sidebar.budgets'),
      icon: <DollarCircleOutlined />,
      providers: allProvidersExceptFocus,
      children: [
        {
          key: 'lighthouse/budget/organizational-map',
          label: t('menu.sidebar.maps'),
        },
        {
          key: 'lighthouse/budget/advanced-chargeback',
          label: t('menu.sidebar.advanced.chargeback'),
          hide: featureFlags.has('advanced-chargeback') ? true : undefined,
        },
        {
          key: 'lighthouse/budget/receivers',
          label: t('menu.sidebar.receivers'),
          global: 'true',
        },
        {
          key: 'lighthouse/budget/provisioners',
          label: t('lighthouse:provisioners'),
          global: 'true',
        },
      ],
    },
    {
      key: 'lighthouse/forecast',
      label: t('router.forecast.title'),
      icon: <RiseOutlined />,
      providers: allProvidersExceptFocus,
      children: [
        {
          key: 'lighthouse/forecast/infrastructure',
          label: t('lighthouse:forecast.infrastructure'),
        },
        {
          key: 'lighthouse/forecast/business',
          label: t('lighthouse:forecast.business'),
          hide: featureFlags.has('forecast-business') ? true : undefined,
        },
      ],
    },
    {
      key: 'lighthouse/alarms',
      label: t('menu.sidebar.alarms'),
      icon: <AlertOutlined />,
      providers: allProvidersExceptFocus,
    },
    {
      key: 'lighthouse/datasets',
      label: t('menu.sidebar.dataset'),
      icon: <DatabaseOutlined />,
      global: 'true',
      permissions: pierRoles,
    },
    {
      key: 'lighthouse/anomalies',
      label: t('menu.sidebar.anomalies'),
      icon: <ExceptionOutlined />,
      providers: ['aws', 'azure', 'gcp'],
      children: [
        {
          key: 'lighthouse/anomalies/infrastructure',
          label: t('menu.sidebar.anomalies.infrastructure'),
        },
        {
          key: 'lighthouse/anomalies/business',
          label: t('menu.sidebar.anomalies.business'),
        },
      ],
    },
    {
      key: 'lighthouse/tag-dictionary',
      label: t('menu.sidebar.tag.dictionary'),
      icon: <BookOutlined />,
      providers: allProvidersExceptFocus,
    },
  ];

  return getOnlyVisibleMenuItems({ items: allItems, adminRole, provider });
};

export default items;
