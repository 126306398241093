import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import ReportsRoutes from './reports/Routes';
import { useTranslation } from 'react-i18next';

const Personas = lazy(() => import('./personas'));
const Templates = lazy(() => import('./templates'));

function Routes() {
  const { t } = useTranslation();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/personas'}
        title={t('lighthouse:executive.summary.personas.title')}
        component={Personas}
      />

      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/templates'}
        title={t('lighthouse:executive.summary.templates.title')}
        component={Templates}
      />

      <Route path="/lighthouse/executive-summary/reports*">
        <ReportsRoutes />
      </Route>
    </Switch>
  );
}

export default Routes;
