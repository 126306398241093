import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { useTranslation } from 'react-i18next';

function Routes() {
  const { t } = useTranslation();

  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/global-fields'}
        title={t('menu.sidebar.calculated.fields.pier')}
        component={lazy(() => import('../calculatedFields/pages/home'))}
      />

      <PrivateRoute
        exact
        path={[
          '/lighthouse/global-fields/new',
          '/lighthouse/global-fields/:id',
        ]}
        title={t('menu.sidebar.calculated.fields.pier')}
        component={lazy(() => import('../calculatedFields/pages/form'))}
      />
    </Switch>
  );
}

export default Routes;
