import { t } from 'i18next';

export const allDataConnectors = () => [
  { kind: 'aws-billing', label: 'AWS Billing', provider: 'aws' },
  { kind: 'gcp-billing', label: 'GCP Billing', provider: 'gcp' },
  { kind: 'oci-billing', label: 'OCI Billing', provider: 'oci' },
  { kind: 'azure-billing', label: 'Azure Billing', provider: 'azure' },
  {
    kind: 'budget',
    label: t('settings:dataconnector.budget.title'),
    includeBillingConnector: true,
  },
  {
    kind: 'forecast',
    label: t('settings:dataconnector.forecast.title'),
    includeBillingConnector: true,
  },
  {
    kind: 'forecast-business',
    label: t('settings:dataconnector.forecast.business.title'),
    includeBillingConnector: true,
  },
  {
    kind: 'anomaly-business',
    label: t('settings:dataconnector.business.anomaly.title'),
    includeBillingConnector: true,
  },
  {
    kind: 'anomaly-infrastructure',
    label: t('settings:dataconnector.infrastructure.anomaly.title'),
    includeBillingConnector: true,
  },
  { kind: 'csv-file', label: t('settings:dataconnector.csv.title') },
  {
    kind: 'cca-checkers',
    label: t('settings:dataconnector.cca.checkers.title'),
  },
  { kind: 'prometheus', label: 'Prometheus', advancedChargeback: true },
  {
    kind: 'aws-athena-chargeback',
    label: 'AWS Athena Chargeback',
    provider: 'aws',
  },
  { kind: 'api', label: 'API' },
  {
    kind: 'cca-rightsizing-aws',
    label: 'CCA Rightsizing AWS',
    provider: 'aws',
  },
  {
    kind: 'cca-smart-instances',
    label: 'CCA Smart Instances',
  },
  {
    kind: 'cca-payment-plans-aws',
    label: 'CCA Payment Plans AWS',
    provider: 'aws',
  },
  {
    kind: 'cca-rightsizing-azure',
    label: 'CCA Rightsizing Azure',
    provider: 'azure',
  },
  { kind: 'cast-ai', label: 'Cast AI', advancedChargeback: true },
  { kind: 'redhat', label: 'Redhat', advancedChargeback: true },
  { kind: 'executive-summary', label: 'Resumo Executivo' },
  { kind: 'datadog', label: 'Datadog', advancedChargeback: true },
  { kind: 'newrelic', label: 'New Relic', advancedChargeback: true },
  { kind: 'blob-storage', label: 'Blob Storage', advancedChargeback: true },
  { kind: 'advanced-chargeback', label: 'Advanced Chargeback' },
  { kind: 'mongodb-atlas', label: 'MongoDB Atlas', advancedChargeback: true },
];

export function getAllDataConnectorKinds(
  { except, provider } = { except: [], provider: null }
) {
  let dataConnectors = allDataConnectors();

  if (Array.isArray(except) && except.length) {
    dataConnectors = dataConnectors.filter(
      (dataconnector) => !except.includes(dataconnector)
    );
  }

  if (!!provider) {
    dataConnectors = dataConnectors.filter((dataConnector) => {
      if (!!dataConnector.provider && dataConnector.provider !== provider) {
        return false;
      }
      return true;
    });
  }

  return dataConnectors.map((dataconnector) => dataconnector.kind);
}

export function getAllDataConnectorsBilling() {
  return allDataConnectors().filter((dc) => dc.kind.includes('-billing'));
}

export function getAllBillingConnectorsKind() {
  return getAllDataConnectorsBilling().map((dc) => dc.kind);
}

export function getDataConnector(kind) {
  const dataConnector = allDataConnectors().find(
    (dataConnector) => dataConnector.kind === kind
  );

  if (!dataConnector) {
    console.error(`DataConnector kind not found: ${kind}`);
    return {};
  }

  return dataConnector;
}
