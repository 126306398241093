import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '@/components/PrivateRoute';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./home'));
const Settings = lazy(() => import('./settings'));

function Routes() {
  const { t } = useTranslation();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/reports'}
        title={t('lighthouse:executive.summary.views.title')}
        component={Home}
      />

      <PrivateRoute
        exact
        path={'/lighthouse/executive-summary/reports/settings'}
        title={t('lighthouse:executive.summary.settings.title')}
        component={Settings}
      />
    </Switch>
  );
}

export default Routes;
