import React, { lazy } from 'react';
import { PrivateRoute } from '@/components';
import { t } from 'i18next';

const Home = lazy(() => import('./home'));
const Schedules = lazy(() => import('./schedules'));

function Routes() {
  return (
    <>
      <PrivateRoute
        exact
        path={`/autofix/start-stop/schedules`}
        title="Autofix Start Stop"
        component={Schedules}
        componentProps={{ resources: ['EC2', 'RDS', 'REDSHIFT'] }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/scheduled-scaling/schedules`}
        title="Autofix Start Stop"
        component={Schedules}
        componentProps={{
          resources: ['ASG', 'ELASTICACHE', 'ELASTICSEARCH', 'RDSIOPS'],
        }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/resource-groups/schedules`}
        title="Autofix Start Stop"
        component={Schedules}
        componentProps={{
          resources: ['RESOURCES_GROUP'],
        }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/start-stop/vm`}
        title="Autofix Start Stop - VM"
        component={Home}
        componentProps={{ resourceCode: 'vm' }}
        providers={['azure']}
      />
      <PrivateRoute
        exact
        path={`/autofix/start-stop/database`}
        title="Autofix Start Stop - Database"
        component={Home}
        componentProps={{ resourceCode: 'database' }}
        providers={['azure']}
      />
      <PrivateRoute
        exact
        path={`/autofix/start-stop/ec2`}
        title="Autofix Start Stop - EC2"
        component={Home}
        componentProps={{ resourceCode: 'ec2' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/start-stop/rds`}
        title="Autofix Start Stop - RDS"
        component={Home}
        componentProps={{ resourceCode: 'rds' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/start-stop/redshift`}
        title="Autofix Start Stop - Redshift"
        component={Home}
        componentProps={{ resourceCode: 'redshift' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/scheduled-scaling/asg`}
        title="Autofix Start Stop - ASG"
        component={Home}
        componentProps={{ resourceCode: 'asg' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/scheduled-scaling/elasticache`}
        title="Autofix Start Stop - ElastiCache"
        component={Home}
        componentProps={{ resourceCode: 'elasticache' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/scheduled-scaling/elasticsearch`}
        title="Autofix Scheduled Scaling - ElasticSearch"
        component={Home}
        componentProps={{ resourceCode: 'elasticsearch' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/scheduled-scaling/rdsiops`}
        title="Autofix Scheduled Scaling - RDS IOPS"
        component={Home}
        componentProps={{ resourceCode: 'rdsiops' }}
        providers={['aws']}
      />
      <PrivateRoute
        exact
        path={`/autofix/resource-groups/groups`}
        title={t('autofix:start.stop.resources.groups')}
        component={Home}
        componentProps={{ resourceCode: 'resources_group' }}
        providers={['aws']}
      />
    </>
  );
}

export default Routes;
